import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About us" />
    <h1>About Us</h1>
    <p>Lori Cooper has been crafting her whole life.</p>
    <Link to="/products">Check out some of our Products</Link>
  </Layout>
)

export default About
